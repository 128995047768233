import { CookieService } from 'ngx-cookie-service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { QueryService } from '../../services/query.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IChatHistory, IQuery } from '../../shared/interfaces/query.interface';
import { faCopy, faHashtag, faTag } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { PromptService } from '../../services/prompt.service';
import { IPrompt } from '../../shared/interfaces/prompt.interface';
import { Meta } from '@angular/platform-browser';
const sessionDays = 30;
const sessionExpires = new Date(Date.now() + sessionDays * 24 * 60 * 60 * 1000);
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
    data: any;
    faTag = faTag;
    faHashtag = faHashtag;
    faCopy = faCopy;
    stage: number = 1;
    query: Observable<any | null> = this.queryService.latestQuery$;
    loading: Observable<boolean> = this.queryService.loading$;
    public $message: BehaviorSubject<IChatHistory | null> = new BehaviorSubject<IChatHistory | null>(null);
    public message$: Observable<IChatHistory | null> = this.$message.asObservable();
    active_prompt: IPrompt | null = null;
    latestId!: string;
    latestToken!: string;
    submitted = false;
    messageForm = this.fb.group({
        message: [null, [Validators.required]],
    });
    private pollSubscription: Subscription = new Subscription;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private queryService: QueryService,
        private promptService: PromptService,
        public fb: FormBuilder,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private meta: Meta
    ) {
        this.meta.addTag({ property: 'og:image', content: 'https://snickersowngoal.co.uk/assets/images/share-image.png' });
        this.meta.addTag({ property: 'og:image:width', content: '300' });
        this.meta.addTag({ property: 'og:image:height', content: '300' });
        // this.userService.verify().subscribe(
        //     response => {
        //     },
        //     error => {
        //         this.router.navigate(['/login']);
        //     }
        // )
    }

    ngOnInit(): void {
        this.data = this.route.snapshot.data['data'];
        // console.log({parentData: this.data})
        this.query.subscribe((data: IQuery) => {
            this.latestId = data?._id;
            this.latestToken = data?.token;
            if (data && data.stage) {
                this.stage = data.stage;
            }
        });
    }
    ngAfterViewInit() {
        this.initMessage();
    }
    ngOnDestroy() {
        if (this.pollSubscription) {
            this.pollSubscription.unsubscribe();
        }
        this.queryService.unLoadConversation();
    }
    initMessage() {
        console.log('CHECK COOKIE SESSION', this.cookieService.check('session'))
        this.cookieService.check('session') ? this.initiateCookieMessage() : this.cookieAccept();
    }
    cookieAccept() {
        this.userService.cookieAccept().subscribe(
            cookieToken => {
                this.cookieService.set('session', cookieToken.token, { expires: sessionExpires, sameSite: 'Lax' });
                this.queryService.fetchMessage(true).subscribe(response => {
                    this.initiateCookieMessage()
                }, error => {
                    //this.initiateCookieMessage();
                });
            },
            error => {
                // console.log(error);
                // this.initiateCookieMessage();
            }
        );
    }
    initiateCookieMessage(): void {
        this.queryService.checkStatus(true);
        this.queryService.latestQuery$.subscribe(query => {
            if (query !== null && query.chat_history.length < 1) {
                setTimeout(() => {
                    this.queryService.appendMessageLocal({
                        content: `This is Jose Mourinho. I've heard your friend scored an own goal`,
                        type: 'video',
                        "mux_id": "Y013S2UpXn01V400ZE3gEfEl4djDp8uuaC8EbLRCOTWmww",
                        "mux_playback_ids": [
                            {
                                "policy": "public",
                                "id": "wk42NPHOQG01023PEFB6bSW1wFy02gNgUPmtGNqE02DLvJo",
                            }
                        ],
                        role: "agent"
                    }, 2000).subscribe(result => {
                        console.log('POSTED MESSAGE', result)
                    });
                }, 1000);
            }
        });
    }
    stageChange(stage: number) {
        this.stage = stage;
        if (stage === 2) {
            this.queryService.checkStatus(true);
        }
    }
    safteyData(data: IChatHistory): void {
        console.log({ EVENT: data });
        this.$message.next(data);
    }
    public submitForm(values: any): void {
        this.submitted = true;
        if (this.messageForm.invalid) {
            return;
        }
        const query: IChatHistory = {
            query: values.message,
            content: values.message,
            type: 'text',
            role: "user",
        }
        this.queryService.appendMessageLocal(query);
        // this.queryService.setLoading(true);
        console.log({ stage: this.stage })
        switch (this.stage) {
            case 3:
                this.queryService.yourName(this.latestId, query).subscribe(
                    response => {
                        console.log(response)
                        this.queryService.checkStatus(true, this.latestToken);
                        this.messageForm.reset();
                    }
                )
                break;
            case 4:
                this.queryService.friendName(this.latestId, query).subscribe(
                    response => {
                        console.log(response)
                        this.queryService.checkStatus(true, this.latestToken);
                        this.messageForm.reset();
                    }
                )
                break;
            case 5:
                this.queryService.ownGoal(this.latestId, query).subscribe(
                    response => {
                        console.log(response)
                        this.queryService.checkStatus(true, this.latestToken);
                        this.messageForm.reset();
                    }
                )
                break;
            default: this.queryService.yourName(this.latestId, query).subscribe(
                response => {
                    console.log(response)
                    this.queryService.checkStatus(true, this.latestToken);
                    this.messageForm.reset();
                }
            )
                break;
        }
    }
    public resetQuery(isTest: boolean): void {
        this.cookieService.delete('session');
        this.stage = 1;
        this.queryService.resetQuery(isTest || true);
    }
    public copyToClipboard(text: string): void {
        // this.toastService.newToast({message:'Copied to clipboard', type:'success'});
        this.toastr.success('Copied', 'Copying to clipboard');
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        } else {
            // Clipboard API not supported, fallback to alternative method
            // Implement your own logic here
        }
    }
    saveQuery(event: { _id: string, query: string, change: string; }): void {
        this.promptService.updateHistory(event._id, event.query, event.change).subscribe(response => {
            this.toastr.success('Saved', 'updated successfully');
        }, error => {
            this.toastr.error('Error', 'unable to save');
        });
    }

}