import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { Injectable } from '@angular/core';
import { QueryService } from '../../services/query.service';
import { ChatService } from '../../services/chat.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { SimulationModule } from '../../simulation/simulation.component.module';



@NgModule({
  declarations: [
    HomeComponent
  ],
  providers: [ QueryService, ChatService],
  imports: [
    CommonModule,
    ToastrModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HomeRoutingModule,
    SimulationModule
  ],
  exports: [
    HomeComponent
  ]
})
export class HomeModule { }
