
<div class="home-header">
    <!-- <div class="header">
        <span><button class="btn" (click)="resetQuery()">Reset</button></span>
    </div> -->
    <div class="sub-header" *ngIf="(query | async); let query">
        <span class="token" (click)="copyToClipboard(query.token)">
            <p>{{query.token}}</p>
            <fa-icon  [icon]="faCopy"></fa-icon>
        </span>
    </div>
</div>
<div class="home-container">
    <div class="overlay"></div>
    <app-simulation-component style="height: 100%;" (saftey)="safteyData($event)" [testing]="true"></app-simulation-component>
</div>